import React, {ChangeEvent, PureComponent} from 'react';
import styles from './Orders.module.scss';
import {
    Badge,
    Button,
    ButtonGroup,
    Col,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownItem,
    DropdownMenu
} from "reactstrap";
import Icon from "../UI/Icon";
import classNames from 'classnames';
import {IOrderShort} from "../../models/Order";
import {IDictionary} from "../../models/Dictionary";
import {getDictionary} from "../../utils/dictionaries";
import {differenceInMinutes, format, parseISO} from "date-fns";
import Order from "../../containers/Order";
import {externalDeliveryServiceLogos, ORDER_PAYMENT_PAID, ORDER_STATUS_NEW} from "../../utils/order";
import ConfirmDialog from "../ConfirmDialog";
import {accessOrderChangeStatus, accessOrderCheckDelete, accessOrderCheckUpload} from "../../utils/user-accesses";
import {Link} from "react-router-dom";
import {courierTypeIcons, getCourierName} from "../../utils/couriers";
import Avatar from "../UI/Avatar";
import {ICityShortResponse} from "../../models/City";
import {getDistanceFromLatLonInKm} from "../../utils/addressHelper";


type OrdersPropsType = {
    toggleActiveId: (id: string) => void;
    activeId: string;
    changeStatus: (id: string, status: number) => void;
    data: IOrderShort[];
    dictionaryOrderStatuses: IDictionary[];
    allowedCities: ICityShortResponse[];
    accesses?: string[];
    isArchive?: boolean;
    addOrderReturnCheck?: (id: string, data: FormData) => void;
    deleteOrderReturnCheck?: (id: string) => void;
}

type OrderDataForShowingReturnCheck = {
    title: string;
    url: string;
}

type OrdersStateType = {
    isStatusChangeConfirm: boolean;
    status: number;
    statusTitle: string;
    id: string;
    orderIdForReturnCheckDelete: string | null;
    orderDataForShowingReturnCheck: OrderDataForShowingReturnCheck | null;
}

class Orders extends PureComponent<OrdersPropsType, OrdersStateType> {
    state: OrdersStateType = {
        isStatusChangeConfirm: false,
        status: Infinity,
        statusTitle: '',
        id: '',
        orderIdForReturnCheckDelete: null,
        orderDataForShowingReturnCheck: null,
    };

    changeStatusHandler = () => {
        const {status, id} = this.state;
        const {changeStatus} = this.props;
        this.setState({isStatusChangeConfirm: false}, () => {
            changeStatus(id, Number(status))
        });
    };

    openStatusChangeConfirm = (id: string, status: number, statusTitle: string) => {
        this.setState({isStatusChangeConfirm: true, status, id, statusTitle})
    };

    closeStatusChangeConfirm = () => {
        this.setState({isStatusChangeConfirm: false, status: Infinity})
    };

    showReturnCheck = (data: OrderDataForShowingReturnCheck) => {
        this.setState({orderDataForShowingReturnCheck: data});
    }

    hideReturnCheck = () => {
        this.setState({orderDataForShowingReturnCheck: null});
    }

    openDeleteReturnCheckConfirm = (orderId: string) => {
        this.setState({orderIdForReturnCheckDelete: orderId});
    }

    closeDeleteReturnCheckConfirm = () => {
        this.setState({orderIdForReturnCheckDelete: null});
    }

    deleteReturnCheckHandler = () => {

        const {deleteOrderReturnCheck} = this.props;
        const {orderIdForReturnCheckDelete} = this.state;

        if (orderIdForReturnCheckDelete && deleteOrderReturnCheck) {
            deleteOrderReturnCheck(orderIdForReturnCheckDelete);
            this.setState({
                orderIdForReturnCheckDelete: null,
            })
        }
    }

    render() {
        const {
            data,
            dictionaryOrderStatuses,
            toggleActiveId,
            activeId,
            accesses,
            isArchive,
            allowedCities,
            addOrderReturnCheck,
        } = this.props;

        const {
            isStatusChangeConfirm,
            statusTitle,
            orderIdForReturnCheckDelete,
            orderDataForShowingReturnCheck,
        } = this.state;

        const canDeleteReturnCheck = Array.isArray(accesses) && accesses.includes(accessOrderCheckDelete);

        return (
            <>
                <table className={styles['table']}>
                    <thead>
                    <tr className={styles['table__item']}>
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Город
                            </div>
                        </td>
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Заказ
                            </div>
                        </td>
                        {isArchive && <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Общее
                            </div>
                        </td>}
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Обработка
                            </div>
                        </td>
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Готовка
                            </div>
                        </td>
                        {isArchive && <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                В пути
                            </div>
                        </td>}
                        {isArchive && <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                S км
                            </div>
                        </td>}
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Ко времени
                            </div>
                        </td>
                        <td className={styles['table__item__td']}>
                            <div className={'semi-bold'}>
                                Дата формирования
                            </div>
                        </td>
                        <td className={styles['table__item__td']}>
                        </td>
                        <td className={styles['table__item__td']}>

                        </td>
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((data) => {
                            return (
                                <OrdersItem
                                    key={data.id}
                                    data={data}
                                    dictionaryOrderStatuses={dictionaryOrderStatuses}
                                    toggleActiveId={toggleActiveId}
                                    activeId={activeId}
                                    accesses={accesses}
                                    isArchive={isArchive}
                                    openStatusChangeConfirm={this.openStatusChangeConfirm}
                                    allowedCities={allowedCities}
                                    addOrderReturnCheck={addOrderReturnCheck}
                                    showReturnCheck={this.showReturnCheck}
                                    openDeleteReturnCheckConfirm={this.openDeleteReturnCheckConfirm}
                                />
                            )
                        }
                    )}
                    </tbody>
                </table>

                <ConfirmDialog
                    isOpen={isStatusChangeConfirm}
                    closeHandler={this.closeStatusChangeConfirm}
                    actionHandler={this.changeStatusHandler}
                    title={statusTitle ? `Изменить статус на «${statusTitle}»?` : "Изменить статус?"}
                    actionButtonText={'Изменить'}
                    cancelButtonText={'Отмена'}
                />

                {isArchive &&
                    <Modal size={'lg'} isOpen={!!orderDataForShowingReturnCheck} toggle={this.hideReturnCheck}>
                        <ModalHeader toggle={this.hideReturnCheck}>{orderDataForShowingReturnCheck?.title}</ModalHeader>
                        <ModalBody className={"d-flex justify-content-center"}>
                            <img style={{maxWidth: "100%", maxHeight: "100%"}} src={orderDataForShowingReturnCheck?.url}
                                 alt={''}/>
                        </ModalBody>
                    </Modal>}

                {isArchive && canDeleteReturnCheck && <Modal size={'md'} isOpen={!!orderIdForReturnCheckDelete}
                                                             toggle={this.closeDeleteReturnCheckConfirm}>
                    <ModalHeader toggle={this.closeDeleteReturnCheckConfirm}>
                        Удалить возвратный чек?
                    </ModalHeader>
                    <ModalFooter style={{borderTop: 'none'}}>
                        <ButtonGroup>
                            <Button
                                onClick={this.closeDeleteReturnCheckConfirm}
                                color={"light"}>
                                Отменить
                            </Button>
                            <Button
                                color={'primary'}
                                onClick={() => this.deleteReturnCheckHandler()}>
                                Удалить
                            </Button>
                        </ButtonGroup>
                    </ModalFooter>
                </Modal>}
            </>
        )
    }
}

type OrdersItemPropsType = {
    data: IOrderShort;
    openStatusChangeConfirm: (id: string, status: number, statusTitle: string) => void;
    showReturnCheck: (data: OrderDataForShowingReturnCheck) => void;
    openDeleteReturnCheckConfirm: (id: string) => void;
} & Pick<
    OrdersPropsType,
    'activeId'
    | 'allowedCities'
    | 'dictionaryOrderStatuses'
    | 'accesses'
    | 'isArchive'
    | 'toggleActiveId'
    | 'addOrderReturnCheck'
>;

type OrdersItemStateType = {
    timer: string;
    isDeleteReturnCheckConfirm: boolean;
    toggleCancel: boolean;
}

class OrdersItem extends PureComponent<OrdersItemPropsType, OrdersItemStateType> {
    interval: any;
    private readonly returnCheckInputRef: React.RefObject<HTMLInputElement>;

    constructor(props: OrdersItemPropsType) {
        super(props);
        this.returnCheckInputRef = React.createRef();
    }

    state: OrdersItemStateType = {
        timer: '',
        isDeleteReturnCheckConfirm: false,
        toggleCancel: false,
    };

    componentDidMount() {
        this.interval = setInterval(() => {
            if (this.props.data.dateTimeStatusFinish) {
                this.setState({timer: this.getRestDateTimeFinish(parseISO(this.props.data.dateTimeStatusFinish))})
            }
        }, 1000)
    }

    componentDidUpdate(prevProps: Readonly<OrdersItemPropsType>) {
        if (prevProps.data.dateTimeStatusFinish !== this.props.data.dateTimeStatusFinish) {
            if (this.props.data.dateTimeStatusFinish) {
                this.setState({timer: this.getRestDateTimeFinish(parseISO(this.props.data.dateTimeStatusFinish))})
            }
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    getRestDateTimeFinish = (date: Date): string => {
        const difference = date.getTime() - Date.now();

        const hours = Math.floor(difference / 3.6e6);
        const minutes = hours * 60 + Math.floor((difference % 3.6e6) / 6e4);
        const seconds = Math.floor((difference % 6e4) / 1000);

        if (difference > 0) {
            return (minutes.toString().length === 1 ? '0' + minutes : minutes) + ':' + (seconds.toString().length === 1 ? '0' + seconds : seconds)
        }
        return '00:00'
    };

    openReturnCheckAddDialog = () => {
        if (this.returnCheckInputRef.current) {
            this.returnCheckInputRef.current.click();
        }
    }

    toggleCancel = () => {
        this.setState({toggleCancel: !this.state.toggleCancel})
    }

    addReturnCheckHandler = (event: ChangeEvent<HTMLInputElement>) => {
        const {data: {id}, addOrderReturnCheck} = this.props;

        if (event.target.files?.[0] && addOrderReturnCheck) {
            const data = new FormData();
            data.append('file', event.target.files?.[0]);

            addOrderReturnCheck(id, data);
        }
    }

    render() {
        const {timer} = this.state;
        const {
            data,
            dictionaryOrderStatuses,
            activeId,
            toggleActiveId,
            accesses,
            isArchive,
            openStatusChangeConfirm,
            allowedCities,
            showReturnCheck,
            openDeleteReturnCheckConfirm,
        } = this.props;

        const {
            id,
            createdAt,
            finishedAt,
            externalId,
            companyName,
            status,
            courier,
            deliveryDateTime,
            timeForReady,
            transitions,
            dateTimeStatusFinish = '',
            redistributed,
            paymentTypeId,
            externalCreatedAt,
            isExternalCourier,
            deliveryService,
            cityId,
            returnCheckUrl,
            point,
            deliveryStartAt,
            deliveryEndAt,
            timeForDelivery,
            isNewClient,
            isPromoUsed,
            addressFrom,
            addressTo
        } = data;

        const dictionaryOrderStatus = getDictionary(dictionaryOrderStatuses, status);
        const statusName = dictionaryOrderStatus ? dictionaryOrderStatus.value : undefined;
        const statusColor = dictionaryOrderStatus ? dictionaryOrderStatus.color : undefined;

        const deliveryDateTimeParsed = deliveryDateTime ? format(parseISO(deliveryDateTime), 'dd-MM-yyyy HH:mm') : undefined;
        const createdAtParsed = createdAt ? format(parseISO(createdAt), 'dd-MM-yyyy HH:mm') : undefined;
        // const externalCreatedAtParsed = externalCreatedAt ? format(parseISO(externalCreatedAt), 'dd-MM-yyyy HH:mm') : undefined;
        const cancelTransitions = Array.isArray(transitions) ? transitions?.filter(({status}) => status <= 0) ?? [] : []

        const cancelTransitionButtons = cancelTransitions && cancelTransitions.length > 0 &&
            <Dropdown
                id="status-dropdown"
                color="danger"
                title="Отменить"
                isOpen={this.state.toggleCancel}
                toggle={this.toggleCancel}
            >
                <DropdownToggle color="danger" caret={true}>Отменить</DropdownToggle>
                <DropdownMenu>
                    {cancelTransitions.map(({status, title}) =>
                        <DropdownItem
                            key={status}
                            onClick={() => openStatusChangeConfirm(id, status, title)}>
                            {title}
                        </DropdownItem>
                    )}
                </DropdownMenu>
            </Dropdown>

        const transitionButtons = Array.isArray(transitions) && transitions?.filter(({status}) => status > 0).map(({
                                                                                                                       status,
                                                                                                                       title
                                                                                                                   }) => {
            const dictionaryOrderStatus = getDictionary(dictionaryOrderStatuses, status);
            const color = dictionaryOrderStatus ? dictionaryOrderStatus.color : undefined;
            return <Button
                className={"text-nowrap"}
                key={status}
                color={color}
                onClick={() => openStatusChangeConfirm(id, status, title)}>
                {title}
            </Button>
        });

        const currentTimer = dateTimeStatusFinish ? timer || this.getRestDateTimeFinish(parseISO(dateTimeStatusFinish)) : '';

        const diffInMinutes = (createdAt && externalCreatedAt) ? differenceInMinutes(parseISO(createdAt), parseISO(externalCreatedAt)) : undefined

        /**
         * Общее время заказа от формирования до завершения курьером
         */
        const orderTotalTime = (createdAt && finishedAt) ? differenceInMinutes(parseISO(finishedAt), parseISO(createdAt)) : null;

        /**
         * Иконка типа курьера
         */
        const icon = typeof courier?.courierTypeId === "number"
            ? courierTypeIcons[courier.courierTypeId]
            : undefined;

        /**
         * Город заказа
         */
        const cityName = allowedCities.find(({id}) => id === cityId)?.name ?? '-';

        /**
         * Права на добавление/удаление возвратных чеков, на изменение статусов
         */
        const canAddReturnCheck = Array.isArray(accesses) && accesses.includes(accessOrderCheckUpload);
        const canDeleteReturnCheck = Array.isArray(accesses) && accesses.includes(accessOrderCheckDelete);
        const canChangeStatuses = Array.isArray(accesses) && accesses.includes(accessOrderChangeStatus);

        /**
         * Показывать ли действия с чеком.
         * Если он есть, то всегда можно его просмотреть. Если его нет, но есть права на добавление, можно добавить.
         */
        const canDoReturnCheckActions = isArchive && (!!returnCheckUrl || canAddReturnCheck);

        /**
         * Показывать ли последний столбец с передвижениями по статусу и действиями возвратного чека архивных заказов
         */
        const canViewOrderActionsCell = canChangeStatuses || canDoReturnCheckActions;

        /**
         * Если пользователь может только просматривать чек, не нужно показывать блоки в две строки.
         * Эта логика верстки делается через JS, а не через CSS, потому что она делается поверх уже существующей legacy-верстки.
         */
        const isSimplifiedActionsCellStyle = returnCheckUrl && !canDeleteReturnCheck;

        /** Оплачен ли заказ онлайн */
        const isPaidOnline = paymentTypeId === ORDER_PAYMENT_PAID;

        /**
         * Вычисленное время, которое курьер потратил на путь
         */
        const calculatedTimeForDelivery = deliveryStartAt && deliveryEndAt
            ? differenceInMinutes(parseISO(deliveryEndAt), parseISO(deliveryStartAt))
            : null;

        /**
         * Отображаемое время, которое курьер потратил на путь.
         * Цвет зависит от того, успел ли курьер доставить за отведенное время
         */
        const displayedActualTimeForDelivery = typeof calculatedTimeForDelivery === "number"
            ? <div className={classNames(
                'semi-bold text-root-size',
                timeForDelivery ? calculatedTimeForDelivery > timeForDelivery ? 'red' : 'green' : ''
            )}>
                {calculatedTimeForDelivery} м
            </div>
            : '-';

        const distance = (addressTo && addressTo.lat && addressTo.long) ? getDistanceFromLatLonInKm({
            lat1: addressFrom.lat,
            lon1: addressFrom.long,
            lat2: addressTo.lat!!,
            lon2: addressTo.long!!,
        }) : null;

        const distanceString = (distance) ? (distance < 0
            ? `${Math.round(distance)} м`
            : `${distance.toFixed(1)} км`) : "н/д";

        return (
            <>
                <tr className={classNames(styles.table__item, activeId === id && styles['table__item--active'], redistributed && styles['table__item--redistributed'], ORDER_STATUS_NEW === status)}
                    onClick={() => toggleActiveId(id)}>
                    <td className={styles['table__item__td']}>
                        {cityName}
                        {point?.name && <><br/>{point.name}</>}
                    </td>

                    <td className={styles['table__item__td']}>
                        <b>№ {id} {externalId != null ? `(${externalId}) ` : ''}</b>
                        <Badge color={statusColor}>{statusName}</Badge>
                        {isPaidOnline && <>&nbsp;<Badge color={'success'}>Оплачен</Badge></>}
                        {!!isNewClient && <>&nbsp;<Badge color={'success'}>1</Badge></>}
                        {!!isPromoUsed && <>&nbsp;<Badge color={'warning'}>P</Badge></>}<br/>
                        {companyName}
                    </td>

                    {isArchive &&
                        <td className={styles['table__item__td']}>
                            <div className='semi-bold text-root-size'>
                                {orderTotalTime != null && <span>{orderTotalTime} м</span>}
                            </div>
                        </td>
                    }

                    <td className={styles['table__item__td']}>
                        {diffInMinutes &&
                            <div className='semi-bold text-root-size'>
                                {diffInMinutes >= 5 ? <span className={"red"}>{diffInMinutes} м</span> :
                                    <span>{diffInMinutes} м</span>}
                            </div>}
                    </td>

                    <td className={styles['table__item__td']}>
                        {timeForReady && <div className='semi-bold text-root-size'>
                            {timeForReady} м
                        </div>}
                    </td>

                    {isArchive && <td className={styles['table__item__td']}>
                        {displayedActualTimeForDelivery}
                    </td>}

                    {isArchive && <td className={styles['table__item__td']}>
                        <div className={'semi-bold'}>
                            {distanceString}
                        </div>
                    </td>}

                    <td className={styles['table__item__td']}>
                        {deliveryDateTimeParsed}
                    </td>

                    <td className={styles['table__item__td']}>
                        <Row form className="align-items-center">
                            {!!icon && <Col md={'auto'}>
                                <Avatar
                                    icon={icon}
                                />
                            </Col>}
                            <Col md={'auto'}>
                                {createdAtParsed && courier &&
                                    <div className={'semi-bold'}>
                                        <Link
                                            to={`/couriers/${courier.id}`}>{getCourierName(courier, {skipMiddle: true})}</Link>
                                    </div>
                                }
                                {createdAtParsed}
                            </Col>
                        </Row>
                    </td>
                    <td className={classNames(styles['table__item__td'])}>
                        <Row>
                            <div className={classNames(
                                styles['table__item__td__timer'],
                                (currentTimer <= '02:00') && styles['table__item__td__timer--red'])}>
                                {currentTimer}
                            </div>
                            {redistributed &&
                                <div className={styles['table__item--redistributed-icon']}>
                                    <Icon className={'mt-1 ml-2'} name={"directions_run"}/>
                                    <Icon name={"priority_high"}/>
                                </div>}
                            {isExternalCourier && deliveryService && <div className="ml-2 align-self-center">
                                <img alt={deliveryService} src={externalDeliveryServiceLogos[deliveryService]}
                                     height={24} width={24}/>
                            </div>}
                        </Row>
                    </td>
                    {canViewOrderActionsCell &&
                        <td style={{width: 1}} className={classNames("mb-n2 mr-n2", styles['table__item__td'])}>
                            <div
                                className={classNames('d-flex justify-content-end', !isSimplifiedActionsCellStyle && 'flex-wrap flex-xl-nowrap')}>
                                {canDoReturnCheckActions &&
                                    <div className='mb-2 mr-2' onClick={e => e.stopPropagation()}>
                                        {(returnCheckUrl
                                            ? <ButtonGroup>
                                                <Button
                                                    size={'sm'}
                                                    color={'outline-light'}
                                                    onClick={() => {
                                                        showReturnCheck({
                                                            title: `Возвратный чек для заказа № ${id} ${externalId != null ? '(' + externalId + ')' : ''}`,
                                                            url: returnCheckUrl
                                                        })
                                                    }}>
                                                    <Icon name={'visibility'} color={'gray-500'}/>
                                                </Button>
                                                {canDeleteReturnCheck && <Button
                                                    className={"text-nowrap"}
                                                    key={status}
                                                    color={'danger'}
                                                    onClick={() => {
                                                        openDeleteReturnCheckConfirm(id);
                                                    }}>
                                                    Удалить возвратный чек
                                                </Button>}
                                            </ButtonGroup>
                                            : <>
                                                <input
                                                    type="file"
                                                    accept="image/x-png,image/jpeg"
                                                    ref={this.returnCheckInputRef}
                                                    style={{display: "none"}}
                                                    onChange={this.addReturnCheckHandler}
                                                />
                                                <Button
                                                    className={"text-nowrap"}
                                                    key={status}
                                                    color={'light'}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        this.openReturnCheckAddDialog();
                                                    }}>
                                                    Возвратный чек
                                                </Button>
                                            </>)}
                                    </div>}
                                <div className="text-right mb-2 mr-2">
                                    <ButtonGroup onClick={(e) => e.stopPropagation()}>
                                        {transitionButtons}{cancelTransitionButtons}
                                    </ButtonGroup>
                                </div>
                            </div>
                        </td>}
                </tr>
                {activeId === id && (
                    <tr className={styles['table__item_more']}>
                        <td colSpan={50} className={styles['table__item_more__td']}>
                            <div className={styles['table__item_more__td__container']}>
                                <Order id={id} isArchive={isArchive}/>
                            </div>
                            <div className={styles['table__item_more__td__toggle']} onClick={() => toggleActiveId(id)}>
                                <Icon
                                    name={'keyboard_arrow_up'}
                                    className={styles['table__item_more__td__toggle-icon']}
                                    color={'gray-500'}/>
                            </div>
                        </td>

                    </tr>
                )}
            </>
        );
    }
}

export default Orders;
