type DistanceCalcParams = {lat1: number; lon1: number; lat2: number; lon2: number;};

/**
 * Вычислить расстояние между двумя точками в километрах
 */
export const getDistanceFromLatLonInKm = ({
  lat1,
  lon1,
  lat2,
  lon2,
}: DistanceCalcParams) => {
  const R = 6371; // Радиус Земли в км

  const deg2rad = (deg: number) => deg * (Math.PI / 180);

  const dLat = deg2rad(lat2-lat1);
  const dLon = deg2rad(lon2-lon1);

  const a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

  return R * c;
}
